import { denormalizeCosmicJSON } from '../../../scripts/prepare-cache/normalize';
import { StaticSelfServiceObject } from '../../types';

export type Json = {
  [key: string]: string | number | boolean | Json | Json[] | null;
};

export type NormalizedData = {
  modules: Json;
  pages: Json;
};

export const fetchCache = async () => {
  const rawCache = await import('./cache.json');
  const appCache = denormalizeCosmicJSON(rawCache);

  const pages = Object.values(
    appCache?.pages || {}
  ) as unknown as StaticSelfServiceObject[];

  return {
    pages: pages.map((page) => ({
      slug: page?.slug,
      locale: page?.locale,
    })),
    pagesMapByLocale: pages.reduce(
      (res, page) => {
        res[page?.locale] = {
          ...res[page?.locale],
          [page?.slug]: page,
        };

        return res;
      },
      {} as { [key: string]: StaticSelfServiceObject }
    ),
    resources: rawCache.resources,
    modules: appCache.modules,
  };
};
