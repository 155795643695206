import { ImageObjectData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import React from 'react';

import {
  ComparisonTableHeader,
  ComparisonTableMetadata,
  ComparisonTableType,
} from './ComparisonSection';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHeader } from './TableHeader';

type TableProps = {
  data: ComparisonTableType;
  image?: ImageObjectData;
  className?: string;
  isLoading: boolean;
  onAddToCart: () => void;
};

const Table = ({
  data,
  className,
  image = {} as ImageObjectData,
  isLoading,
  onAddToCart,
}: TableProps) => {
  const { metadata = {} as ComparisonTableMetadata, items = [] } = data;

  const headers = items.map((item) => ({
    featured: item.featured,
    ...(item.header || ({} as ComparisonTableHeader)),
  }));

  return (
    <table className={cn('border-spacing-0', className)}>
      <TableHeader header={metadata.header} image={image} items={headers} />
      <TableBody dimensions={metadata.dimensions} items={items} />
      <TableFooter
        isLoading={isLoading}
        onAddToCart={onAddToCart}
        title={metadata.footer.title}
        items={items}
      />
    </table>
  );
};

export default Table;
