'use client';
import { PlatformCosmicModule } from '@finn/ui-cosmic';
import { useEffect, useState } from 'react';

import { fetchCache } from './helpers';

export const useModuleDefaults = (slug: string, locale: string = 'de-DE') => {
  const [content, setContent] = useState<PlatformCosmicModule | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // we load cache in async way to avoid large bundle size
  useEffect(() => {
    fetchCache()
      .then((data) => {
        setContent(
          (Object.values(data.modules) as PlatformCosmicModule[]).find(
            (module) =>
              module?.slug === slug &&
              (locale ? module?.locale === locale : true)
          ) as PlatformCosmicModule
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [locale, slug]);

  return [content, isLoading] as const;
};
