import { Button } from '@finn/design-system/atoms/button';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';

export const AddToCart = ({
  title,
  onAddToCart,
  isLoading,
}: {
  title: string;
  onAddToCart: () => void;
  isLoading: boolean;
}) => {
  const handleClick = () => {
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location: 'comparison',
    });

    onAddToCart();
  };

  return (
    <Button
      data-cy="submit"
      loading={isLoading}
      onClick={handleClick}
      variant="outlineWhite"
    >
      {title}
    </Button>
  );
};
