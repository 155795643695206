import { AccordionCosmicData } from './accordion';
import { DataDashboardCosmicData } from './B2BHandoverCoordination';
import { BrandSliderCosmicData } from './brandSlider';
import { CosmicModuleType, HTMLContentData } from './commons';
import { ContentCosmicData } from './content';
import { ContentSliderCosmicData } from './contentSlider';
import { CustomData } from './custom';
import { FeaturedVehicleCosmicData } from './featuredvehicle';
import { FlipperCosmicData } from './flipper';
import { FormCosmicData } from './form';
import { GalleryCosmicData } from './gallery';
import { HeroCosmicData } from './hero';
import { ModalCosmicData } from './modal';
import { NPSSliderCosmicData } from './npsSlider';
import { PromoCosmicData } from './promo';
import { StepperCosmicData } from './stepper';
import { StepsCounterCosmicData } from './stepsCounter';
import { TextblockCosmicData } from './textblock';
import { VerticalTabCosmicData } from './verticaltab';

export enum FormSlug {
  B2B = 'form-b2b',
  CONTACT_US = 'form-contact',
  REFERRAL_VOUCHER = 'form-referral-voucher',
  RETENTION_VOUCHER = 'form-retention-voucher',
  JOBAUTO = 'form-jobauto-version-2',
  PARTNERSHIPS = 'form-partnerships',
  CANCELATION = 'form-cancelation',
  CANCELATION_CONFIRMATION = 'form-cancelation-confirmation',
  B2B_LEAD_GENERATION_FORM = 'form-b2b-lead-generation',
  PREQUALIFY = 'form-prequalify',
}

export enum FlipperSlug {
  FINN_FOR_FRIENDS = 'flipper-finnforfriends',
}

export enum DashboardSlug {
  B2B_HANDOVER = 'data-dashboard-b2b-handover-coordination',
  B2B_RETURN = 'data-dashboard-b2b-return-coordination',
}

export enum HeroSlug {
  HOME = 'new-homepage-hero',
}

export type PlatformCosmicModule = {
  id: string;
  slug: string;
  locale?: string;
  data?: PlatformCosmicModule;
  devonly?: boolean;
  config?: {
    gap_between_moduels?: number;
  };
  text?: string;
  banners?: object[];
  fields?: any[];
  metadata: {
    fields?: any[];
    translations?: { [key: string]: string };
    gap_between_modules?: number;
    columns?: number;
    modules?: PlatformCosmicModule[];
    steps?: PlatformCosmicModule[];
    html_id?: string;
    devonly?: boolean;
  };
  modules?: PlatformCosmicModule[];
  type?: CosmicModuleType;
  type_slug?: string;
};

export type ModuleType =
  | DataDashboardCosmicData
  | BrandSliderCosmicData
  | AccordionCosmicData
  | ContentCosmicData
  | ContentSliderCosmicData
  | FeaturedVehicleCosmicData
  | FlipperCosmicData
  | GalleryCosmicData
  | HeroCosmicData
  | ModalCosmicData
  | NPSSliderCosmicData
  | PromoCosmicData
  | StepperCosmicData
  | StepsCounterCosmicData
  | TextblockCosmicData
  | VerticalTabCosmicData
  | HTMLContentData
  | FormCosmicData
  | PlatformCosmicModule
  | CustomData;
