import { Button } from '@finn/design-system/atoms/button';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn, useIntl } from '@finn/ui-utils';
import { useCallback } from 'react';

type Props = {
  onClick: () => void;
  isLoading?: boolean;
  testId?: string;
  location?: string;
  isInPDP?: boolean;
  title?: string;
};

export const ContinueButton = ({
  onClick,
  location,
  isLoading,
  testId,
  isInPDP,
  title,
}: Props) => {
  const i18n = useIntl();

  const handleClick = useCallback(() => {
    interactionTrackingEvent(TrackingEventName.CTA_CLICKED, {
      location,
    });

    onClick();
  }, [onClick]);

  const defaultTitle = i18n.formatMessage('pdp.continueToBooking');

  return (
    <>
      <Button
        loading={isLoading}
        testId={testId || 'submit'}
        className={cn(!isInPDP && 'mt-10', 'w-full')}
        disabled={isLoading}
        onClick={handleClick}
      >
        {title ?? defaultTitle}
      </Button>
    </>
  );
};
