'use client';
import { useMediaQuery } from '@finn/design-system/helpers/media';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { CTA } from '@finn/ui-components';
import { AccordionCosmicMetadata, AccordionLayoutKeys } from '@finn/ui-cosmic';
import { cn, parseToHtml, useSession } from '@finn/ui-utils';
import React, { useEffect, useState } from 'react';

import { TypographyWithAutoLink } from '../../components/TypographyWithAutoLink';
import { Collapse } from './Collapse';

interface IProps {
  data: AccordionCosmicMetadata;
  isInPLP?: boolean;
}

const getTitle = (
  data: AccordionCosmicMetadata,
  isInPLP: boolean | undefined
) => {
  return (
    data.title && (
      <TypographyWithAutoLink
        variant="h3"
        linkText={data.title}
        className={cn('mobile-t2-semibold md:web-t3-semibold mb-6', {
          'mobile-t4-semibold md:web-t4-semibold': isInPLP,
        })}
      >
        {parseToHtml(data.title)}
      </TypographyWithAutoLink>
    )
  );
};

export const Accordion: React.FunctionComponent<IProps> = ({
  data,
  isInPLP,
}) => {
  const [session] = useSession();
  const [ctaMetadata, setCtaMetadata] = useState(data?.cta?.metadata);
  const layoutWithDescription =
    data?.layout?.key === AccordionLayoutKeys.WITH_DESCRIPTION;
  const customerId = session?.user?.hs_contact_id;

  useEffect(() => {
    if (
      customerId &&
      ctaMetadata?.href === 'https://support.finn.com/hc/de-de'
    ) {
      setCtaMetadata({
        ...ctaMetadata,
        href: `https://support.finn.com/hc/de-de?customer_id=${customerId}`,
      });
    }
  }, [ctaMetadata, customerId]);

  const isMobileScreen = useMediaQuery('sm');

  const isCTAAboveFAQ =
    isMobileScreen && data?.layout_options?.is_cta_above_faq_items;

  const showCTABelowDescription = !isMobileScreen || isCTAAboveFAQ;

  return (
    <div className="container">
      <div
        className={cn('grid grid-cols-1', {
          'gap-6 md:grid-cols-[2fr_3fr]': layoutWithDescription,
        })}
      >
        {layoutWithDescription ? (
          <div className="pr-6">
            {getTitle(data, isInPLP)}
            <div className="body-16-light [&>p]:body-16-light mb-6 mt-2 [&>p]:py-1">
              {parseToHtml(data.description_text || '')}
            </div>
            {showCTABelowDescription && ctaMetadata ? (
              <CTA data={ctaMetadata} parentBG="light" />
            ) : null}
          </div>
        ) : null}
        <div>
          {!layoutWithDescription && getTitle(data, isInPLP)}
          {data.items?.map((item, index) => {
            const handleChange = (values?: string[]) => {
              interactionTrackingEvent(TrackingEventName.DROPDOWN_CLICKED, {
                action: values?.length ? 'Open Dropdown' : 'Close Dropdown',
                name: item.metadata.title,
              });
            };

            return (
              <Collapse
                key={index}
                index={index}
                title={
                  <h3 className="body-16-semibold">
                    {parseToHtml(item.metadata.title)}
                  </h3>
                }
                onValueChange={handleChange}
              >
                {/**
                 * as we render custom html inside, we can not use emulation for vertica-trim
                 * so we need to set before,after to hidden
                 */}
                <div className="body-16-light [&>p]:body-16-light before:hidden after:hidden [&>p]:py-1">
                  {parseToHtml(item.metadata.description)}
                </div>
              </Collapse>
            );
          })}
          {layoutWithDescription && !showCTABelowDescription ? (
            <div className="mt-6">
              {ctaMetadata && <CTA data={ctaMetadata} parentBG="light" />}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
