import { cn, getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import { ReactNode } from 'react';

import {
  GenericVehicleDetails,
  getConfigLineLabel,
  Image,
} from '../../product-cards';

const IMAGE_WIDTH = 146;
const IMAGE_HEIGHT = 96;

export const VehicleHeader = ({
  vehicle,
  children,
}: {
  vehicle: GenericVehicleDetails;
  children?: ReactNode;
}) => {
  const vehiclePictureURL = vehicle.picture.url;
  const doesProductImageExist = Boolean(vehiclePictureURL);
  const vehicleImageUrl = doesProductImageExist
    ? getCloudinaryImgUrl(vehiclePictureURL, {
        w: IMAGE_WIDTH,
        h: IMAGE_HEIGHT,
        c: 'fill',
        dpr: 2,
        fl: 'progressive',
      })
    : '';
  const srcSet =
    vehicleImageUrl && getCloudinarySrcSet(vehicleImageUrl, [IMAGE_WIDTH]);

  const vehicleAltText = `${vehicle.brand?.id} ${vehicle.model}`;

  return (
    <div className="flex gap-4">
      <div className="bg-snow w-[146px] pt-4 text-center">
        <Image
          src={vehicleImageUrl}
          srcSet={srcSet}
          sizes={`142px`}
          alt={vehicleAltText}
          className="max-w-[90%]"
        />
      </div>

      <div
        className={cn('flex flex-col justify-center gap-2', {
          'justify-between py-2': Boolean(children),
        })}
      >
        <div className="flex flex-col justify-center gap-2">
          <span className="body-16-semibold">
            {vehicle.brand.id} {vehicle.model}
          </span>
          <span className="body-14-regular">{getConfigLineLabel(vehicle)}</span>
        </div>
        {children}
      </div>
    </div>
  );
};
