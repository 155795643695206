'use client';
import { useSession } from '@finn/ui-utils';
import Script from 'next/script';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ChatRef } from './types';
import { globalChatApi } from './UltimateChat';

type DecagonChatProps = {
  openOnLoad?: boolean;
  onChatOpened?: () => void;
};

export const DecagonChat = forwardRef<ChatRef, DecagonChatProps>(
  ({ openOnLoad, onChatOpened }, ref) => {
    const fallbackChatRef = useRef<ChatRef>(null);
    const chatRef = ref || fallbackChatRef;
    const [isWidgetInitialized, setIsWidgetInitialized] = useState(false);
    const queueRef = useRef<(() => void)[]>([]);
    const [session] = useSession();
    const scriptId = 'decagon-chat-loader';
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Track chat events
    const setupEventListener = useCallback(() => {
      if (!window.duet) {
        return;
      }

      window.duet.setEventListener((event) => {
        if (event.eventName === 'DUET_INITIALIZE' && onChatOpened) {
          onChatOpened();
        }
      });
    }, [onChatOpened]);

    // Authenticate with Decagon
    const authenticateWithDecagon = useCallback(async () => {
      if (!isWidgetInitialized || !session?.user) {
        return;
      }

      try {
        const response = await fetch('/api/generateDecagonToken');
        const data = await response.json();

        if (data.success && data.token && window.duet) {
          const { user_id, signature, epoch } = data.token;

          window.duet.setUserId(user_id);
          window.duet.setUserIdAuth(signature, epoch);

          // Set user metadata
          const metadata: Record<string, any> = {};
          if (session.user.email) {
            metadata.email = session.user.email;
          }
          if (session.user.hs_first_name) {
            metadata.name = session.user.hs_first_name;
          }
          window.duet.setMetadata(metadata);

          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error authenticating with Decagon:', error);
      }
    }, [isWidgetInitialized, session]);

    // Initialize widget when script loads
    const initializeWidget = useCallback(() => {
      if (!window.duet) {
        return;
      }
      setIsWidgetInitialized(true);
      setupEventListener();
    }, [setupEventListener]);

    // Handle authentication and queue processing after widget is initialized
    useEffect(() => {
      if (!isWidgetInitialized) {
        return;
      }

      const handleAuth = async () => {
        await authenticateWithDecagon();

        // Process queue after authentication
        if (isAuthenticated && queueRef.current.length) {
          queueRef.current.forEach((fn) => fn());
          queueRef.current = [];
        }

        // Open chat if needed
        if (openOnLoad && isAuthenticated) {
          window.duet?.open();
        }

        // Set global chat API reference
        globalChatApi.current = (chatRef as typeof globalChatApi).current;
      };

      handleAuth();
    }, [
      isWidgetInitialized,
      authenticateWithDecagon,
      isAuthenticated,
      openOnLoad,
      chatRef,
    ]);

    // Expose chat methods via ref
    useImperativeHandle(chatRef, () => ({
      openWidget: async () => {
        if (isWidgetInitialized && isAuthenticated) {
          window.duet?.open();
        } else if (isWidgetInitialized) {
          // Wait for authentication to complete before opening
          await authenticateWithDecagon();
          if (isAuthenticated) {
            window.duet?.open();
          } else {
            queueRef.current.push(() => window.duet?.open());
          }
        } else {
          queueRef.current.push(() => window.duet?.open());
        }
      },
      closeWidget: () => window.duet?.close(),
    }));

    return (
      <Script
        id={scriptId}
        src={`https://decagon.ai/loaders/finn.js`}
        strategy="lazyOnload"
        onLoad={initializeWidget}
      />
    );
  }
);
