import { useFormattedPrice } from '@finn/ui-utils/lib/localization/index';

export type ComparisonTableHeaderPrice = {
  value: number;
  description: string;
};

export const Price = ({
  price,
  className,
}: {
  price: ComparisonTableHeaderPrice;
  className?: string;
}) => {
  const priceValue = useFormattedPrice(price.value, 0);

  return (
    <div className={className}>
      <span className="mobile-t4-semibold md:web-t4-semibold">
        {priceValue}
      </span>
      <span className="body-14-regular ml-2 text-white sm:ml-0">
        {price.description}
      </span>
    </div>
  );
};
