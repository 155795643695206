import CheckoutContext from '@finn/auto-ui/contexts/Checkout';
import { useClosedUserGroup } from '@finn/auto-ui/contexts/ClosedUserGroup';
import { useExtendedAccount } from '@finn/auto-ui/contexts/ExtendedAccount';
import { useVoucherStore } from '@finn/auto-ui/modules/checkout/stores/useVoucherStore';
import { directCheckout } from '@finn/auto-ui/services/checkout';
import { Login } from '@finn/platform-modules';
import { useWebViewNavigationEnded } from '@finn/ua-app';
import { usePCPLoginExp } from '@finn/ua-featureflags';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import {
  ConfigureVehicleModal,
  GenericVehicleDetails,
  getCheckoutCartUrl,
  isVehicleComingSoon,
  LoginHeader,
  useConfigureStore,
  useMonthlySubscriptionCost,
} from '@finn/ua-vehicle';
import { useIntl, useSession } from '@finn/ui-utils';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import NotifyMeModal from '~/components/UserAccount/Modals/NotifyMeModal';
import { useSaveCartCookie } from '~/modules/checkout/hooks/useSaveCartCookie';

const ConfigureVehicleModalWithData = (props) => {
  const {
    setExtendedAccountInProgress,
    createLead,
    extendedAccountInProgress,
  } = useExtendedAccount();
  const hasVoucher = useVoucherStore((state) => state.hasVoucher);
  const { closedUserGroupId } = useClosedUserGroup();
  const { cartInfo } = useContext(CheckoutContext);
  const { isEnabled: isExpPCPLogin, isVariantC: isExpPCPLoginCVariant } =
    usePCPLoginExp();
  const {
    downPayment: isDownPayment,
    term,
    kilometerPackage,
    vehicle,
  } = useConfigureStore();

  const downPaymentAmountInVehicle =
    vehicle?.downpayment_prices?.downpayment_fixed_amount;

  const { total } = useMonthlySubscriptionCost(vehicle);
  const intl = useIntl();
  const finalPriceFormatted = intl.formatNumber(Math.ceil(total), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const buildLoginSection = useMemo(() => {
    if (!isExpPCPLogin) {
      return null;
    }

    let loginHeaderComponent = null;
    if (isExpPCPLoginCVariant) {
      loginHeaderComponent = (
        <LoginHeader
          isDownPayment={isDownPayment}
          vehicle={vehicle}
          term={term}
          kmPackage={kilometerPackage}
          formattedPrice={finalPriceFormatted}
        />
      );
    }

    const vehicleId = vehicle?.id ?? '';

    return (
      <Login
        isLoadingRestore={extendedAccountInProgress}
        loginHeaderComponent={loginHeaderComponent}
        vehiclePower={vehicle?.power || null}
        createLead={(formData) =>
          createLead(formData, {
            term,
            kilometerPackage,
            vehicleId,
            downpaymentSelected: isDownPayment,
          })
        }
        setExtendedAccountInProgress={setExtendedAccountInProgress}
        cartURL={getCheckoutCartUrl(
          vehicleId,
          term,
          kilometerPackage,
          isDownPayment ? downPaymentAmountInVehicle : undefined
        )}
      />
    );
  }, [
    downPaymentAmountInVehicle,
    isExpPCPLogin,
    isExpPCPLoginCVariant,
    vehicle?.id,
    kilometerPackage,
    term,
    finalPriceFormatted,
    createLead,
    setExtendedAccountInProgress,
  ]);

  const [session] = useSession();

  return (
    <ConfigureVehicleModal
      directCheckout={directCheckout}
      buildLoginSection={buildLoginSection}
      closedUserGroupId={closedUserGroupId}
      hasVoucher={hasVoucher}
      cartInfo={cartInfo}
      setExtendedAccountInProgress={setExtendedAccountInProgress}
      extendedAccountInProgress={extendedAccountInProgress}
      session={session}
      {...props}
    />
  );
};

interface IAddToCartContext {
  onAddToCart?: () => void;
  isLoading: boolean;
  initialized: boolean;
}

export const AddToCartContext = React.createContext<IAddToCartContext>({
  isLoading: false,
  initialized: false,
});

export const AddToCartProvider: React.FC<{
  vehicle: GenericVehicleDetails;
  children?: ReactNode;
}> = ({ children, vehicle }) => {
  const { term, kilometerPackage } = useConfigureStore();

  const isComingSoon = isVehicleComingSoon(vehicle);

  const dealInfo = useMemo(
    () => ({
      vehicleId: vehicle.id,
      term,
      kilometerPackage,
    }),
    [kilometerPackage, term, vehicle.id]
  );

  useSaveCartCookie({
    isPDP: true,
    cartInfo: dealInfo,
    vehicle,
  });

  const openModal = useOpenModal();

  const [isLoading, setLoading] = useState(false);
  // used to disable loading in webview after navigation is done
  useWebViewNavigationEnded(isLoading, setLoading);

  const onAddToCart = useCallback(async () => {
    if (isComingSoon || vehicle.availability === null) {
      interactionTrackingEvent(TrackingEventName.COMING_SOON_MODAL_OPENED, {
        contentIds: [vehicle.id],
        make: vehicle.brand.id,
        model: vehicle.model,
      });

      openModal(ModalKey.NOTIFY_ME);

      return;
    }

    openModal(ModalKey.CONFIGURE_VEHICLE);
    setLoading(false);
    interactionTrackingEvent(TrackingEventName.MODAL_OPENED, {
      name: 'configure',
    });
  }, [
    isComingSoon,
    vehicle.availability,
    vehicle.id,
    vehicle.brand.id,
    vehicle.model,
    openModal,
  ]);

  return (
    <>
      <AddToCartContext.Provider
        value={{
          onAddToCart,
          isLoading,
          initialized: true,
        }}
      >
        {children}
      </AddToCartContext.Provider>

      <ModalContainer
        modalKey={ModalKey.NOTIFY_ME}
        ModalComponent={NotifyMeModal}
        vehicle={vehicle}
      />

      <ModalContainer
        modalKey={ModalKey.CONFIGURE_VEHICLE}
        ModalComponent={ConfigureVehicleModalWithData}
      />
    </>
  );
};
