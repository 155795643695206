import { RawHtml } from '@finn/platform-modules';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { cn } from '@finn/ui-utils';

import { CheckboxTableCell } from './CheckboxTableCell';
import { ComparisonTableItemBaseValue } from './ComparisonSection';
import { TextTableCell } from './TextTableCell';

export const TableCellData = ({
  featured,
  data,
}: {
  featured: boolean;
  data: ComparisonTableItemBaseValue;
}) => {
  let Component;
  switch (data.type) {
    case 'checkbox':
      Component = CheckboxTableCell;
      break;
    case 'text':
      Component = TextTableCell;
      break;
  }

  const handleSourceLinkClicked = (sourceType: string) => {
    interactionTrackingEvent(TrackingEventName.SOURCE_LINK_CLICKED, {
      type: sourceType,
    });
  };

  return (
    <div
      className={cn('flex items-center justify-center', {
        'flex-col': data?.additionalText,
      })}
    >
      {Component && <Component featured={featured} value={data.value} />}
      {data.misc}
      {data?.additionalText && (
        <div
          onClick={
            data?.sourceType &&
            (() => handleSourceLinkClicked(data?.sourceType))
          }
        >
          <RawHtml html={data.additionalText} className="body-light-12" />
        </div>
      )}
    </div>
  );
};
