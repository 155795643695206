import { Button } from '@finn/design-system/atoms/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@finn/design-system/atoms/tooltip';
import { Check } from '@finn/design-system/icons/check';
import { useIntl } from '@finn/ui-utils';
import { useState } from 'react';

export const PayAsYouGoFakedoor = () => {
  const i18n = useIntl();
  const [isCopied, setIsCopied] = useState(false);

  const voucherCode = i18n.formatMessage('pdp.payAsYouGo.fakedoor.voucherCode');
  const copyVoucherCode = () => {
    navigator.clipboard.writeText(voucherCode);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <div className="mt-12">
      <h6 className="mobile-t4-semibold mb-8">
        {i18n.formatMessage('pdp.payAsYouGo.fakedoor.title')}
      </h6>
      <p className="body-16-regular mb-6">
        {i18n.formatMessage('pdp.payAsYouGo.fakedoor.description')}
      </p>

      <div className="border-pearl flex flex-row items-center justify-between rounded-sm border border-solid px-4 py-3">
        <span className="body-16-regular">{voucherCode}</span>

        <Button
          className="uppercase focus:bg-white"
          variant="ghost"
          size="md"
          onClick={copyVoucherCode}
        >
          {isCopied ? (
            <span className="flex flex-row items-center gap-2">
              <Tooltip defaultOpen>
                <TooltipTrigger>
                  <Check className="fill-trustedBlue" />
                </TooltipTrigger>
                <TooltipContent
                  body={i18n.formatMessage('pdp.payAsYouGo.fakedoor.copied')}
                />
              </Tooltip>
            </span>
          ) : (
            i18n.formatMessage('pdp.payAsYouGo.fakedoor.copy')
          )}
        </Button>
      </div>
    </div>
  );
};
