import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { ElementType, FC, ReactNode } from 'react';

import { ToggleGroup } from '../../atoms/toggle';
import { ToggleGroupProps } from '../../atoms/toggle/ToggleGroup';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../atoms/tooltip';

type ToggleModuleProps = ToggleGroupProps & {
  title?: string;
  titleAs?: ElementType;
  subtitle?: string;
  tooltipIcon?: ReactNode;
  tooltipText?: string;
  onTooltipOpenChange?: (opened: boolean) => void;
};

export const ToggleModule: FC<ToggleModuleProps> = ({
  title,
  titleAs,
  subtitle,
  tooltipIcon,
  tooltipText,
  onTooltipOpenChange,
  children,
  ...restProps
}) => {
  const TitleTag = titleAs ?? 'p';

  return (
    <div className="w-full">
      {title ? (
        <div className="mb-4 flex items-center">
          <TitleTag className="body-16-semibold mr-1">{title}</TitleTag>
          {tooltipText ? (
            <Tooltip onOpenChange={onTooltipOpenChange}>
              <TooltipTrigger>
                {tooltipIcon ?? <InfoOutlined width={16} height={16} />}
              </TooltipTrigger>
              <TooltipContent body={tooltipText} />
            </Tooltip>
          ) : null}
        </div>
      ) : null}
      <ToggleGroup {...restProps}>{children}</ToggleGroup>
      {subtitle ? <p className="body-14-regular mt-4">{subtitle}</p> : null}
    </div>
  );
};
