import { Badge } from '@finn/design-system/atoms/badge';
import { useLoginText } from '@finn/platform-modules';
import { DEFAULT_DISCOUNT_PERCENTAGE } from '@finn/ua-constants';
import { getTermLabel, useIntl } from '@finn/ui-utils';

import { VehicleHeader } from '../../pdp';
import { GenericVehicleDetails } from '../../product-cards';

type LoginHeaderProps = {
  vehicle: GenericVehicleDetails;
  term: number;
  kmPackage: number;
  formattedPrice: string;
  isDownPayment?: boolean;
};

export const LoginHeader = ({
  vehicle,
  term,
  kmPackage,
  formattedPrice,
  isDownPayment,
}: LoginHeaderProps) => {
  const intl = useIntl();
  const { subtitle } = useLoginText();

  const flexTermLabel = intl.formatMessage('checkout.flexTermLabel');
  const downPaymentDiscountLabel = intl.formatMessage(
    'checkout.downpayment.discountLabel',
    { discountPercentage: DEFAULT_DISCOUNT_PERCENTAGE }
  );

  return (
    <div className="flex flex-col space-y-6">
      <VehicleHeader vehicle={vehicle} />
      <hr className="border-pearl w-full border-0 border-b border-solid" />
      <div className="grid grid-cols-2 gap-y-4">
        <span className="body-14-light">
          {intl.formatMessage('checkout.contractDuration')}
        </span>
        <span className="body-14-semibold">
          {getTermLabel(term, flexTermLabel, intl)}
        </span>

        <span className="body-14-light">
          {intl.formatMessage('checkout.kmIncluded')}
        </span>
        <span className="body-14-semibold">
          {intl.formatMessage('checkout.kmPackageForMonth', { kmPackage })}
        </span>

        <span className="body-14-light">
          {intl.formatMessage('checkout.monthlyTotalLabel')}
        </span>
        <span className="body-14-semibold">
          {formattedPrice} {intl.formatMessage('checkout.vatLabelWithVat')}
          {isDownPayment && (
            <div className="pt-1">
              <Badge
                variant="monochromeBlue"
                withStroke
                className="body-12-medium h-[19px]"
              >
                {downPaymentDiscountLabel}
              </Badge>
            </div>
          )}
        </span>
      </div>
      <hr className="border-pearl w-full border-0 border-b border-solid" />
      <p className="body-16-light pb-2">{subtitle}</p>
    </div>
  );
};
