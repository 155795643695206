import { cn } from '@finn/ui-utils';

type RawHtmlProps = {
  html: string;
  className?: string;
  useGlobalStyles?: boolean;
  fullWidthMedia?: boolean;
};

export const RawHtml = ({
  html,
  className,
  useGlobalStyles = false,
  fullWidthMedia = false,
}: RawHtmlProps) => {
  return (
    <div
      className={cn(
        className,
        { 'raw-html-global': useGlobalStyles },
        { 'raw-html-full-width': fullWidthMedia }
      )}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
