import { useIsPayAsYouGo } from '@finn/ua-featureflags';
import { useContext } from 'react';

import { useConfigureStore } from '../../pdp-management';
import { GenericVehicleDetails, VehicleContext } from '../../product-cards';
import { useCalculatePrice } from '../../product-pricing';

export const useMonthlySubscriptionCost = (
  initVehicle?: GenericVehicleDetails
) => {
  const { vehicle } = useContext(VehicleContext);
  const { term, kilometerPackage, downPayment, downPaymentAmount } =
    useConfigureStore();
  const isPayAsYouGo = useIsPayAsYouGo(kilometerPackage);

  const priceParams = {
    term,
    kilometerPackage,
    downPaymentAmount,
    isDownPayment: downPayment,
    vehicle: vehicle || initVehicle,
    isPayAsYouGo,
  };

  const monthlyCost = useCalculatePrice(priceParams);

  const total = monthlyCost;

  return { monthlyCost, total };
};
