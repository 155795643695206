import { ToggleItem } from '@finn/design-system/atoms/toggle';
import { InfoOutlined } from '@finn/design-system/icons/info-outlined';
import { ToggleModule } from '@finn/design-system/modules/ToggleModule';
import { FALLBACK_ADDITIONAL_MILEAGE_FEE } from '@finn/ua-constants';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import {
  getFormattedNumber,
  getFormattedPrice,
  twoDecimalPlaces,
  useIntl,
} from '@finn/ui-utils';
import get from 'lodash/get';
import { useCallback, useContext } from 'react';

import { getForBusinessCookie } from '../../../helpers';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { PDPTracking, useFormattedNumber, useTrackingData } from '../../pdp';
import { useConfigureStore } from '../../pdp-management';
import { VehicleContext } from '../../product-cards';
import { calculateTotalPrice, getDistancePrices } from '../../product-pricing';
import {
  calculateBasePriceDiscount,
  PAY_AS_YOU_GO_PRICE_FACTOR,
} from '../../product-pricing/helpers/prices';

type Price = { distance: number; price: number; duration: number };

const onTooltipOpenChange = (opened: boolean) => {
  if (opened) {
    interactionTrackingEvent(TrackingEventName.TOOLTIP_HOVERED, {
      name: 'distance options tooltip',
    });
  }
};

export const DistanceOptions = ({
  showPayAsYouGo = false,
}: {
  showPayAsYouGo?: boolean;
}) => {
  const i18n = useIntl();
  const isForBusiness = getForBusinessCookie();
  const { vehicle } = useContext(VehicleContext);
  const {
    setKilometerPackage,
    kilometerPackage,
    term,
    downPayment: isDownPayment,
  } = useConfigureStore();

  const { priceType, forceRegularPrice } = useDiscoveryConfig();

  const vehiclePrice = get(
    vehicle,
    forceRegularPrice ? 'price' : 'downpayment_prices',
    {}
  ) as Record<string, number>;

  const distancePrices: Price[] = getDistancePrices(
    vehiclePrice,
    isForBusiness,
    priceType,
    term,
    forceRegularPrice
  );

  const unitPrice = get(
    vehicle,
    'price.extra_km_price',
    FALLBACK_ADDITIONAL_MILEAGE_FEE
  );

  const extraUnitPrice = useFormattedNumber(
    twoDecimalPlaces(priceType === 'cents' ? unitPrice / 100 : unitPrice)
  );

  const distanceUnit = i18n.formatMessage('general.distanceUnit');

  const title = i18n.formatMessage('pdp.mileagePerMonth');

  const disclaimer = i18n.formatMessage(
    forceRegularPrice
      ? `pdp.mileagePackageDisclaimer.b2b`
      : `pdp.mileagePackageDisclaimer.b2c`,
    { extraUnitPrice }
  );

  const disclaimerTooltip = i18n.formatMessage(
    'pdp.mileagePackageDisclaimerTooltip'
  );

  const trackingData = useTrackingData();
  const trackDistanceClicked = useCallback(
    (newDistance: number) => {
      const newPrice = calculateTotalPrice({
        vehicle,
        term,
        kilometerPackage: newDistance,
        isDownPayment,
        isPayAsYouGo: newDistance === 0,
      });

      PDPTracking.distanceClicked({
        ...trackingData,
        distancepackage: newDistance,
        price: newPrice,
      });
    },
    [trackingData, vehicle, term, isDownPayment]
  );

  const kmPrice = getFormattedPrice(
    i18n,
    vehicle.price.msrp * PAY_AS_YOU_GO_PRICE_FACTOR,
    2
  );
  const basePriceDiscount = getFormattedPrice(
    i18n,
    calculateBasePriceDiscount(vehicle),
    0
  );

  return (
    <div className="pb-6">
      <ToggleModule
        className="mt-1"
        cols={2}
        title={title}
        titleAs={'h6'}
        onTooltipOpenChange={onTooltipOpenChange}
        tooltipText={
          showPayAsYouGo ? i18n.formatMessage('pdp.payAsYouGo.tooltip') : ''
        }
        tooltipIcon={
          showPayAsYouGo ? (
            <InfoOutlined className="ml-1" width={16} height={16} />
          ) : null
        }
        defaultValue={String(kilometerPackage)}
        value={String(kilometerPackage)}
        onValueChange={(value) => {
          if (value) {
            const newValue = Number(value);
            setKilometerPackage(newValue);
            trackDistanceClicked(newValue);
          }
        }}
        subtitle={`${disclaimer} ${disclaimerTooltip}`}
      >
        {showPayAsYouGo && (
          <ToggleItem
            key="0"
            variant="regular"
            title={i18n.formatMessage('pdp.payAsYouGo.title')}
            value="0"
            description={`- ${basePriceDiscount} (${kmPrice}/ km)`}
          />
        )}
        {distancePrices.map((option) => {
          const description =
            option.price > 0
              ? `+ ${getFormattedPrice(i18n, option.price, 0)}`
              : i18n.formatMessage('pdp.free');

          return (
            <ToggleItem
              key={option.distance}
              variant="regular"
              title={`${getFormattedNumber(
                i18n,
                option.distance
              )} ${distanceUnit}`}
              value={String(option.distance)}
              description={description}
            />
          );
        })}
      </ToggleModule>
    </div>
  );
};
