import { AddToCart } from './AddToCart';
import LinkFooterAction from './LinkFooterAction';

type ComparisonTableFooterActionType = 'link' | 'add_to_cart' | 'b2b_buy_now';

export type ComparisonTableBaseAction = {
  type: ComparisonTableFooterActionType;
  title: string;
};

export type ComparisonTableFooterLinkAction = ComparisonTableBaseAction & {
  type: 'link';
  href: string;
};

export const Action = ({
  action,
  fullWidth,
  isLoading,
  onAddToCart,
}: {
  action: ComparisonTableBaseAction;
  fullWidth?: boolean;
  isLoading: boolean;
  onAddToCart: () => void;
}) => {
  switch (action.type) {
    case 'link':
      return (
        <LinkFooterAction
          action={action as ComparisonTableFooterLinkAction}
          fullWidth={fullWidth}
        />
      );
    case 'add_to_cart':
      return (
        <AddToCart
          title={action.title}
          isLoading={isLoading}
          onAddToCart={onAddToCart}
        />
      );
    default:
      return null;
  }
};
