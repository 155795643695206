import { useMediaQuery } from '@finn/design-system/helpers/media';
import { ImageObjectData, UIBaseCosmicObject } from '@finn/ui-cosmic';
import { parseToHtml } from '@finn/ui-utils';

import { ComparisonTableBaseAction } from './Action';
import { ComparisonAccordion } from './ComparisonAccordian';
import ComparisonTable from './ComparisonTable';
import { ComparisonTableHeaderPrice } from './Price';

export type ComparisonCosmicMetadata = {
  description: string;
  title: string;
  table: ComparisonTableType;
  header_image?: ImageObjectData;
};

export type ComparisonCosmicData = UIBaseCosmicObject<ComparisonCosmicMetadata>;

type ComparisonTableItemValueType = 'checkbox' | 'text';

export type ComparisonTableItemBaseValue = {
  type: ComparisonTableItemValueType;
  value: any;
  additionalText?: string;
  sourceType?: string;
  misc?: '';
};

export type ComparisonTableMetadataDimension = {
  id: string;
  name: string;
  description?: string;
  additionalText?: string;
};

export type ComparisonTableMetadata = {
  header: {
    title: string;
  };
  footer: { title: string };
  dimensions: ComparisonTableMetadataDimension[];
};

export type ComparisonTableHeader = {
  title: string;
  price?: ComparisonTableHeaderPrice;
};

export type ComparisonTableFooter = {
  price?: ComparisonTableHeaderPrice;
  action?: ComparisonTableBaseAction;
};

export type ComparisonTableItem = {
  featured: boolean;
  header: ComparisonTableHeader;
  footer?: ComparisonTableFooter;
  values: { [key: string]: ComparisonTableItemBaseValue };
};

export type ComparisonTableType = {
  metadata: ComparisonTableMetadata;
  items: ComparisonTableItem[];
  summaryRow?: string;
};

export const ComparisonSection = ({
  data,
  isLoading,
  onAddToCart,
}: {
  data: ComparisonCosmicMetadata;
  isLoading: boolean;
  onAddToCart: () => void;
}) => {
  const {
    title,
    description,
    table = {} as ComparisonTableType,
    header_image: tableImage,
  } = data;

  const isMobile = useMediaQuery('md');

  return (
    <div className="container">
      {title !== '' && (
        <h2 className="mobile-t3-semibold md:web-t3-semibold mb-6 w-[960px] max-w-full">
          {title}
        </h2>
      )}

      {description !== '' && (
        <div className="body-16-light [&>p]:body-16-light mb-10 w-[960px] max-w-full md:mb-20 [&>p]:py-1">
          {parseToHtml(description)}
        </div>
      )}

      {!isMobile && (
        <ComparisonTable
          isLoading={isLoading}
          onAddToCart={onAddToCart}
          data={table}
          image={tableImage}
        />
      )}

      {isMobile && (
        <ComparisonAccordion
          data={table}
          isLoading={isLoading}
          onAddToCart={onAddToCart}
        />
      )}
    </div>
  );
};
