import { useMediaQuery } from '@finn/design-system/helpers/media';
import { Logo } from '@finn/design-system/icons/logo';
import { Image } from '@finn/ui-components';
import { ImageObjectData } from '@finn/ui-cosmic';
import { cn, getCloudinaryImgUrl } from '@finn/ui-utils';

import { ComparisonTableHeader } from './ComparisonSection';
import { Price } from './Price';

export type ComparisonTableHeaderItem = ComparisonTableHeader & {
  featured: boolean;
};

type Props = {
  header: {
    title: string;
    subtitle?: string;
  };
  items: ComparisonTableHeaderItem[];
  alternativeVariant?: boolean;
  image?: ImageObjectData;
};

export const TableHeader = ({
  header,
  items,
  alternativeVariant = false,
  image = {} as ImageObjectData,
}: Props) => {
  const isMobile = useMediaQuery('sm');

  const { title = '', subtitle = '' } = header;

  const TitleSection = () => (
    <div className="mb-3 flex justify-between gap-3 text-left sm:mr-3 md:mb-0">
      <div className="flex flex-col gap-3 sm:my-2">
        <span className="body-14-light">{subtitle}</span>
        <span className="global-t5-semibold">{title}</span>
      </div>
      <div>
        <Image
          src={getCloudinaryImgUrl(image?.image?.url)}
          alt={image?.alt_text}
          className="h-[75px]"
        />
      </div>
    </div>
  );

  return (
    <thead>
      {isMobile && (
        <tr className="h-[90px]">
          <td colSpan={3}>
            <TitleSection />
          </td>
        </tr>
      )}
      <tr className="h-[90px]">
        <th>{alternativeVariant ? !isMobile && <TitleSection /> : title}</th>
        {items.map((item, idx) => (
          <th
            key={idx}
            className={cn('px-0 py-6 align-bottom', {
              'rounded-tl-sm rounded-tr-sm bg-black text-white': item.featured,
              'body-14-light md:body-16-light': alternativeVariant,
            })}
          >
            {item.price ? (
              <Price price={item.price} />
            ) : (
              <div
                className={cn('body-16-semibold min-w-[88px]', {
                  'mt-2': alternativeVariant,
                })}
              >
                {item.featured ? (
                  <Logo
                    className={cn('-mb-1 fill-white', {
                      'w-12': alternativeVariant,
                    })}
                  />
                ) : (
                  <div
                    className={cn({
                      'mb-1': alternativeVariant,
                    })}
                  >
                    {item.title}
                  </div>
                )}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
