import { trackEvent, useCurrentLocale } from '@finn/ui-utils';
import { forwardRef } from 'react';

import { useModuleDefaults } from '../../services/content/useModuleDefaults';
import { DecagonChat } from './DecagonChat';
import { SmoochChat } from './SmoochChat';
import { ChatRef } from './types';
import { UltimateChat } from './UltimateChat';

type ChatProps = {
  translations?: { [key: string]: string };
  variant?: 'zendesk' | 'ultimate' | 'decagon';
};

const handleChatOpen = () => trackEvent('Chat Opened', {});

export const Chat = forwardRef<ChatRef, ChatProps>((props, ref) => {
  const { locale } = useCurrentLocale();
  const [content, isLoading] = useModuleDefaults('chat', locale);
  const { variant = 'zendesk' } = props;

  // in case if chat is used outside of new self service platform
  // we will use default translations, defined in cosmic chat module https://app-v1.cosmicjs.com/finnauto/edit-object/65dcc7a9d9e9da4617dd6719
  const translations =
    props.translations || content?.metadata?.translations || {};

  // if chat used outside platform, we need to wait for translations to be loaded
  if (isLoading && !props.translations) {
    return null;
  }

  switch (variant) {
    case 'zendesk':
      return (
        <SmoochChat
          {...props}
          translations={translations}
          onChatOpened={handleChatOpen}
          ref={ref}
        />
      );
    case 'ultimate':
      return (
        <UltimateChat {...props} ref={ref} onChatOpened={handleChatOpen} />
      );
    case 'decagon':
      return <DecagonChat onChatOpened={handleChatOpen} ref={ref} />;
    default:
      return (
        <SmoochChat
          {...props}
          translations={translations}
          onChatOpened={handleChatOpen}
          ref={ref}
        />
      );
  }
});

export * from './SmoochChat';
export * from './DecagonChat';
export * from './UltimateChat';
export * from './types';
